




































































































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { isArray, sortBy } from 'lodash';
import { plainToClass } from 'class-transformer';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConversationService from '@/services/crm/conversation.service';
import HistoryTypeModel from '@/models/crm/history-type.model';
import AttendanceModel from '@/models/crm/attendance.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import UserModel from '@/models/user.model';
import ActivityService from '@/services/crm/activity.service';
import ConversationModel from '@/models/crm/conversation.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ContactModel from '@/models/crm/contact.model';
import ContactService from '@/services/crm/contact.service';
import CrmChatHistoryMessagesViewer from '../../components/crm/chat-history-messages-viewer.vue';

type DataGridFilterConfig = {
  client: string[] | null;
  prospect: number[] | null;
  periodType: PeriodTypeEnum;
  period: (Date | undefined)[];
  status: StatusEnum | null;
  department: ConversationDepartmentModel[] | null;
  attendant: AttendanceModel[] | null;
  historyType: HistoryTypeModel[] | null;
  protocolNumber: string | null;
  contact: ContactModel[] | null;
};

enum PeriodTypeEnum {
  OpenDate = 'OPEN_DATE',
  ClosingDate = 'CLOSING_DATE',
}

enum StatusEnum {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

interface ISelectOption<T> {
  code: T;
  description: string;
}

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
  },
})
export default class CrmOrders extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataUltimaMensagem', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.chats.grid.lastAttendance')}`,
        colId: 'lastAttendance',
        field: 'dataUltimaMensagem',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataUltimaMensagem || params.data.dataInicio || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.contato?.nome || params.data.numeroWhatsapp;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        cellClass: 'cell-grid-link',
        maxWidth: 150,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.department')}`,
        colId: 'department',
        field: 'departamento.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.historyType')}`,
        colId: 'historyType',
        field: 'tipoHistorico.nome',
        flex: 0.8,
        sortable: true,
      },
    ],
  };

  items: ConversationModel[] = [];

  selected: ConversationModel[] = [];

  // #region filters

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  multipleFilterChanged = false;

  filters: DataGridFilterConfig = {
    client: null,
    prospect: null,
    periodType: PeriodTypeEnum.OpenDate,
    period: [dayjs().startOf('year').toDate(), dayjs().toDate()],
    status: null,
    department: null,
    attendant: null,
    historyType: null,
    protocolNumber: null,
    contact: null,
  };

  periodTypeOptions: ISelectOption<PeriodTypeEnum>[] = [];

  statusOptions: ISelectOption<StatusEnum>[] = [];

  departmentOptions: ConversationDepartmentModel[] = [];

  contactOptions: ContactModel[] = [];

  attendantOptions: UserModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  // #endregion

  dialogConfig: IKeyValue<IDialogConfig> = {
    history: {
      show: false,
      conversation: null,
    },
  };

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadFilterOptions();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }

    if (this.isProspectType) {
      this.filters.prospect = [parseInt(this.getClientIdFromRoute(), 10)];
    } else {
      this.filters.client = [this.getClientIdFromRoute()];
    }

    this.loadItems();
  }

  onFilterChange(type: string): void {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    this.multipleFilterChanged = false;
    this.loadItems();
  }

  async onCellClick(event: IGridCellEvent<ConversationModel>): Promise<void> {
    if (!event.data || event?.colDef?.colId !== 'protocol') {
      return;
    }

    this.openHistoryConversationDialog(plainToClass(ConversationModel, event.data));
  }

  openHistoryConversationDialog(conversation: ConversationModel): void {
    if (!conversation) {
      return;
    }

    this.dialogConfig.history.show = true;
    this.dialogConfig.history.conversation = conversation;
  }

  onCloseHistoryDialogClose(): void {
    this.dialogConfig.history.show = false;
    this.dialogConfig.history.conversation = false;
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ConversationModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ConversationModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.items.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Conversas',
        fileName: ConversationService.generateConversationReportExportFilename(new Date()),
      });
    }
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore = ['periodType'];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get viewTitle(): string {
    return this.$t('crm.view.chats.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }

  get historyDialogTitle(): string {
    const protocol = (this.dialogConfig.history.conversation as ConversationModel)?.protocolo;
    return this.$t('crm.view.chats.dialog.history.title', { protocol }).toString();
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    // this.items = await this.orderService.getOrders(this.getClientIdFromRoute());
    this.items = (
      await this.conversationService.queryConversations(
        1,
        999,
        this.filters as unknown,
        // orderBy,
      )
    ).results;
    this.gridSettings.loading = false;
  }

  private async loadFilterOptions(): Promise<void> {
    this.periodTypeOptions = this.getPeriodTypes();
    this.statusOptions = this.getStatusOptions();

    const tasks = [
      this.getDepartments().then((result) => {
        this.departmentOptions = result;
      }),
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
      this.getHistoryTypes().then((result) => {
        this.historyTypeOptions = result;
      }),
      this.getContacts().then((result) => {
        this.contactOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private getPeriodTypes(): ISelectOption<PeriodTypeEnum>[] {
    return [
      {
        code: PeriodTypeEnum.OpenDate,
        description: this.$t('crm.view.chats.filter.openDate').toString(),
      },
      {
        code: PeriodTypeEnum.ClosingDate,
        description: this.$t('crm.view.chats.filter.closingDate').toString(),
      },
    ];
  }

  private getStatusOptions(): ISelectOption<StatusEnum>[] {
    return [
      {
        code: StatusEnum.Open,
        description: this.$t('crm.view.chats.filter.open').toString(),
      },
      {
        code: StatusEnum.Closed,
        description: this.$t('crm.view.chats.filter.closed').toString(),
      },
    ];
  }

  private async getDepartments(): Promise<ConversationDepartmentModel[]> {
    return this.conversationService.getDepartments();
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants();
  }

  private async getContacts(): Promise<ContactModel[]> {
    return this.contactService.getContacts(this.getClientIdFromRoute(), this.clientType);
  }

  private async getHistoryTypes(): Promise<HistoryTypeModel[]> {
    return sortBy(await this.activityService.getHistoryTypes(), 'nome');
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
